<template>
  <v-container>
    <h2 class="mb-1">Download Our App</h2>
    <!-- <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ siteData.phone }}
    </p> -->
    <v-row>
      <v-col cols="12" md="6">
        <v-card color="#0D01C755">
          <v-container>
            <div class="text-center headline heading bold">
              Get on App Store
            </div>
            <div>
              <v-img src="../../assets/img/mockup_mobile.png" />
            </div>
            <div>
              <v-btn
                href="https://bit.ly/3s4zABm"
                target="_blank"
                large
                block
                color="primary"
              >
                Download
              </v-btn>
            </div>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card color="#FDCA2555">
          <v-container>
            <div class="text-center headline heading bold">
              Get on Play Store
            </div>
            <div>
              <v-img src="../../assets/img/mockup_mobile.png" />
            </div>
            <div>
              <v-btn
                target="_blank"
                href="https://bit.ly/3s4zABm"
                large
                block
                color="accent"
              >
                Download
              </v-btn>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      siteData: (state) => state.user.siteSettings
    })
  }
}
</script>

<style></style>
